<template>
  <div class="container">
    <div class="feedback" v-show="!this.isReceived && this.isReceived !== null">
      <h1>Вы уже оставляли отзыв</h1>
    </div>
    <div class="rewiew" v-show="this.isReceived">
      <h1>Ваш отзыв</h1>
      <fieldset class="box">
        <p class="text">Оцените пожалуйста покупку</p>
        <div class="rating">
          <v-rating
            color="warning"
            hover
            half-increments
            length="5"
            size="50"
            value="2.5"
            v-model="form.rating"
          ></v-rating>
        </div>
        <textarea
          v-model="form.about"
          placeholder="Расскажите о покупке..."
        ></textarea>
      </fieldset>
      <v-btn
        :disabled="form.rating === ''"
        large
        color="orange"
        elevation="5"
        @click="submitReview"
        >отправить
      </v-btn>
    </div>
    <div class="not-found hide">
      <h1>Страница не найденна</h1>
    </div>
    <div class="thanks hide">
      <h1>Спасибо за отзыв</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Review",
  data() {
    return {
      form: {
        rating: "",
        about: "",
      },
      id: "",
      isReceived: null,
      phone: "",
    };
  },
  methods: {
    async submitReview() {
      try {
        let dataForm = {
          id: this.id,
          rating: this.form.rating,
          text: this.form.about,
        };

        console.warn(dataForm, "click");
        let a = await this.$axios
          .post(this.$enums.Endpoints.Sms.Post, dataForm)
          .then((result) => {
            if (result.status === 200) {
              document.querySelector(".thanks").classList.remove("hide");
              document.querySelector(".rewiew").classList.add("hide");
            }
          });
        console.warn(a);
      } catch (e) {
        console.warn(e.response);
      }
    },
  },
  async mounted() {
    const data = await this.$axios
      .get(this.$enums.Endpoints.Sms.Get + `?id=${this.$route.params.id}`)
      .then((result) => result.data)
      .catch((err) => {
        document.querySelector(".not-found").classList.remove("hide");
        console.log(err, "get");
      });

    this.phone = data.phone;
    this.id = data.id;
    this.isReceived = data.isReceived;

    console.log(data);
  },
};
</script>

<style scoped>
.hide {
  display: none !important;
}

.container {
  text-align: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found {
  display: block;
  margin: 60px 0;
}

.feedback {
  display: block;
  margin: 60px 0;
}

.thanks {
  display: block;
  margin: 60px 0;
}

.text {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

fieldset {
  border: 0;
  display: flex;
  flex-direction: row;
  padding: 0 3rem;
  justify-content: space-between;
  margin: 3rem 0 2rem;
  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    margin: 3rem 0;
    padding: 0;
  }
}

.inputText {
  padding: 9px 0 10px 10px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
}

.inputText:focus {
  outline: 2px solid #64dd17;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 43%;
  @media (max-width: 800px) {
    width: 80%;
  }
}

textarea {
  width: 43%;
  height: 16rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  resize: none;
  padding: 10px;
  margin-top: 1rem;
  @media (max-width: 800px) {
    width: 70%;
    height: 12rem;
  }
}
textarea:focus {
  outline: 2px solid #64dd17;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
